import { Box, Flex, Image, Link, LinkProps, Text } from "@chakra-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Container from "./Container";
import SocialIcons from "./SocialIcons";

interface Props {}

const Footer: React.FC<Props> = () => {
  const {
    allContentfulNavigationGroup: { nodes }
  } = useStaticQuery(
    graphql`
      query {
        allContentfulNavigationGroup(
          filter: { placement: { eq: "Footer" } }
          sort: { fields: [order], order: ASC }
        ) {
          nodes {
            id
            title
            navLinks {
              id
              label
              url
            }
          }
        }
      }
    `
  );

  return (
    <Flex color="black" bg="primary.yellow" py={[8, 8, 20, 20]}>
      <Container>
        <Flex
          flexWrap="wrap"
          width="full"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <Flex
              flexWrap="wrap"
              borderBottomWidth="1px"
              borderBottomColor="white"
              pb={5}
            >
              {nodes.map(
                (node: {
                  id: string;
                  title: string;
                  navLinks: { id: string; label: string; url: string }[];
                }) => (
                  <Box
                    width={["50%", "50%", "inherit", "inherit"]}
                    mr={[0, 0, 20, 20]}
                    mb={[8, 8, 8, 0]}
                    key={node.id}
                  >
                    <FooterHeader>{node.title}</FooterHeader>
                    {node.navLinks.map(link => (
                      <FooterLink key={link.id} href={link.url}>
                        {link.label}
                      </FooterLink>
                    ))}
                  </Box>
                )
              )}
            </Flex>
            <SocialIcons />
          </Box>
          <Link href="https://australian.museum/">
            <Image mt={[10, 10, 0, 0]} src="/am_logo_black.svg" />
          </Link>
        </Flex>
      </Container>
    </Flex>
  );
};

const FooterHeader: React.FC = ({ children }) => {
  return (
    <Text fontSize="lg" mb={3} fontWeight="semibold">
      {children}
    </Text>
  );
};

const FooterLink: React.FC<LinkProps> = props => {
  return <Link display="block" {...props} />;
};

export default Footer;
