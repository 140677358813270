import { BoxProps, Link, LinkProps, Stack } from "@chakra-ui/core";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  IconDefinition
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  iconSize?: string;
}

const SocialIcons: React.FC<Props & BoxProps> = ({ iconSize, ...props }) => {
  return (
    <Stack mt={6} isInline spacing={5} {...props}>
      <IconLink
        href="https://www.facebook.com/FrogIDAus"
        icon={faFacebookSquare}
      />
      <IconLink href="https://twitter.com/FrogIDAus" icon={faTwitterSquare} />
      <IconLink
        href="https://instagram.com/FrogIDAus"
        icon={faInstagramSquare}
      />
    </Stack>
  );
};

export const IconLink: React.FC<LinkProps & { icon: IconDefinition }> = ({
  icon,
  ...props
}) => {
  return (
    <Link fontSize="1.8rem" {...props}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  );
};

export default SocialIcons;
