import { Button, ButtonProps, Flex, Image, Link, Box } from "@chakra-ui/core";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import React from "react";
import theme from "../gatsby-plugin-chakra-ui/theme";
import Container from "./Container";
import MobileNav from "./MobileNav";

interface Props {}

const TopNav: React.FC<Props> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulNavigationGroup(filter: { placement: { eq: "Top" } }) {
          nodes {
            id
            title
            navLinks {
              id
              label
              url
            }
          }
        }
      }
    `
  );

  const navLinks = data.allContentfulNavigationGroup.nodes[0]?.navLinks || [];

  const downloadctastyle = {
    display: "inline-block",
    verticalAlign: "middle",
    height: "2.5rem",
    marginLeft: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    fontWeight: "600",
    color: "black",
    background: "#71BE47",
    borderRadius: "9999px",
  };

  return (
    <Flex color="white" bg="primary.black" py={[5, 5, 10, 10]}>
      <Container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <GatsbyLink to="/">
          <Image
            width={["58px", "58px", "116px", "116px"]}
            src="/frogid_logo_rgb_white.svg"
          />
        </GatsbyLink>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <MobileNav
            links={navLinks}
            display={["block", "block", "block", "block", "none"]}
          />
          <Flex display={["none", "none", "none", "none", "block"]} flexWrap="wrap">
            {navLinks.map((link: { id: string; url: string; label: string }) => (
              <NavLink key={link.id} href={link.url}>
                {link.label}
              </NavLink>
            ))}
          </Flex>
          <a style={downloadctastyle} href="/get-involved/#3d45b2b6-c3a2-532a-b00b-4124c646adb9">
            Download App
          </a>
        </Box>
      </Container>
    </Flex>
  );
};

export const NavLink: React.FC<{
  href: string;
} & ButtonProps> = ({ href, children }) => {
  if (href.includes("http")) {
    return (
      <Link _hover={{ textDecor: "none" }} href={href}>
        <LinkButton>{children}</LinkButton>
      </Link>
    );
  }

  return (
    <GatsbyLink
      activeStyle={{
        display: "inline-block",
        borderRadius: "30rem",
        background: theme.colors.primary.yellow,
        color: theme.colors.primary.black,
      }}
      to={href}
    >
      <LinkButton>{children}</LinkButton>
    </GatsbyLink>
  );
};

const LinkButton: React.FC<{ active?: boolean } & ButtonProps> = ({
  active,
  ...props
}) => {
  return (
    <Button
      rounded="full"
      px={4}
      py={0}
      mx={1}
      bg={active ? "primary.yellow" : "transparent"}
      _hover={{ bg: "transparent" }}
      _focus={{ bg: "transparent" }}
      {...props}
    />
  );
};

export default TopNav;
