import {
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,

  Flex, useDisclosure
} from "@chakra-ui/core";
import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "./TopNav";

const MobileNav: React.FC<{
  links: {
    id: string;
    url: string;
    label: string;
  }[];
} & BoxProps> = ({ links, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box {...props}>
      <Box onClick={onOpen} as="button">
        <Box fontSize="1.5rem">
          <FontAwesomeIcon icon={faBars} />
        </Box>
      </Box>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="primary.black" color="white">
          <DrawerBody>
            <Button
              onMouseDown={onClose}
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="2xl"
              variant="unstyled"
              position="absolute"
              top={5}
              right={5}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
            <Flex py={5} mr={16} flexDirection="column">
              <NavLink href="/" display="block" my={6}>
                Home
              </NavLink>
              {links.map(link => (
                <NavLink key={link.id} href={link.url} display="block" my={6}>
                  {link.label}
                </NavLink>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileNav;
