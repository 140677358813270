import { Box, BoxProps } from "@chakra-ui/core";
import React from "react";

interface Props {}

const Container: React.FC<Props & BoxProps> = ({ children, ...props }) => {
  return (
    <Box width={["85%", "85%", "85%", "85%", "6xl"]} mx="auto" {...props}>
      {children}
    </Box>
  );
};

export default Container;
