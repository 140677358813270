import { Box, CSSReset } from "@chakra-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import TopNav from "../components/TopNav";

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <CSSReset />
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="theme-color" content="#E57500" />
        {/* GMaps */}
        <script
          type="text/javascript"
          src="https://maps.google.com/maps/api/js?key=AIzaSyAdO7rUsv6lJHq2ERTulAmqv4z8V4UFXIQ&libraries=places,geometry"
        ></script>
        {/* GTM */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NG5V7SR');`}
        </script>
        {/* Global site tag (gtag.js) - Google Marketing Platform */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=DC-4833109"
        ></script>
        <script>{`      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'DC-4833109');`}</script>
        {/* CampaignMonitor */}
        <script
          type="text/javascript"
          src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
        ></script>
      </Helmet>
      <Box>
        <TopNav />
        <Box width="full">{children}</Box>
        <Footer />
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
